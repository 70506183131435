export const technologie = [
    {
        key: 1,
        subHeading: 'responsywność',
        heading: 'Responsywne strony internetowe',
        intro: 'RWD - responsive web desigdn, to podstawa. Wszystkie nasze strony i aplikacje są dostosowane do zmieniającej się szerokości ekranu wyświetlania.',
        text: 'Coraz więcej osób korzysta z Internetu za pomocą urządzeń przenośnych i ponad 60% ruchu internetowego generowanego jest przez urządzenia mobilne. Wszechobecna sieć bezprzewodowa wifi oraz sieć komórkowa powoduje, że korzystamy z Internetu wszędzie, a ponad 40% zakupów dokonywanych przez Internet pochodzi z urządzeń przenośnych. Dzięki Responsive Web Design:',
        list: [
            'wygląd strony jest w pełni skalowalny i automatycznie dostosowuje się do szerokości okna przeglądarki,',
            '"pływający" układ elementów dostosowujący się do urządzenia,',
            'tekst jest tak samo czytelny zarówno na urządzeniach moblinych, jak i ekranach monitorów,',
            'strony dostosowują się do dowolnej rozdzielczości ekranu urządzeń mobilnych,',
        ]
    },
    {
        key: 2,
        subHeading: 'SEO',
        heading: 'Optymalizacja pod kątem SEO',
        intro: 'Tworzone przez nas strony internetowe spełniają podstawowe założenia SEO, co stanowi cenny punkt wyjścia dla pozycjonowania.',
        text: 'Pozycjonowanie SEO to długotrwały i złożony proces promocji stron i sklepów internetowych obejmujący aspekty techniczne, semantyczne oraz marketingowe. Wdrażane przez nas witryny internetowe tworzone są w oparciu o aktualne wytyczne Google, a ich wydajność testowana narzędziami diagnostycznymi Google.',
        list: [
            'semantyczny i wydajny kod źródłowy strony,',
            'wysoka wydajność i szybkość ładowania,',
            'dostępność na urządzeniach mobilnych,',
            'bogate, w pełni edytowalne tytuły, znaczniki META oraz Open Graph dla każdej podstrony,',
            'mikrodane schema.org,',
            'automatycznie generowany plik robots.txt oraz sitemap.xml,',
            'zarządzanie opisami ALT dla zdjęć,',
            'przyjazne adresy URL.',
        ]
    },
    {
        key: 3,
        subHeading: 'UI / UX design',
        heading: 'Funkcjonalność',
        intro: 'Dbamy o to by wszystkie nasze wiryny były przyjazne użytkownikowi (user frendly), to oznacza, że kontentrujemy się na odbiorcy - użytkowniku treści, jego potrzebach oraz odczuciach.',
        text: 'Jeśli znamy i rozumiemy potrzeby i doświadczenia użytkowników korzystających z naszych produktów, będziemy mogli zaprojektować dla nich jeszcze lepszy produkt. Termin user-fridenly, czyli przyjazny dla użytkownika wydaje nam się oczywisty. To co jest przyjazne dla użytkownika jest łatwe w obsłudze i dostępne dla wszystkich. Cechy strony przyjaznej użytkownikowi:',
        list: [
            'szyybki czxas ładowania strony www,',
            'kompatybilność z urządzeniami mobilnymi,',
            'intuicyjna nawigacja, dostępna w każdym momencie przeglądania strony,',
            'strona dostosowana do wieku i doświadczenia użytkowników.'
        ]
    },
    {
        key: 4,
        subHeading: 'Semantyczność',
        heading: 'Semantyczny kod oraz standardy W3C',
        intro: 'Semantyczny kod - czyli zgodny ze sztuką programowania, pozwala na lepszą optymalizację stron pod kątem SEO oraz dostępność dla użytkowników.',
        text: 'Semantyczny HTML jest ważny dla osób, które korzystają z czytników ekranowych, które odczytują na głos elementy strony oraz pozwalają nawigować na pomocą klawiatury pomiędzy konkretnymi elementami strony.Warto zwrócić uwagę, że coraz więcej osób korzysta z technologii asystujących, wobec czego zaniedbując poprawne wykorzystanie semantyki HTML, możemy w znaczący sposób ograniczyć ilość osób, do których dotrą nasze treści.',
        list: [
            'poprawa dostępności strony dla użytkowników kożystających z czytników,',
            'lepsza optymalizacja strony pod kątem SEO,',
            'lepsza optymalizacja strony pod kątem działania robotów indexujących.',
        ]
    },
]