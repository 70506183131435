import React from "react"
import styled from 'styled-components'
import { down } from 'styled-breakpoints';
import { graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
//DATA
import { proj, www, app, cms } from '../assets/data/oferta'
import { technologie } from '../assets/data/technologie'
//STYLES
import { H1, H4, H2, H5, LabelBW, Text } from "../utils/typo"
import { ContainerMob, CardWrapper, Section } from '../utils/utils'
//COMPONENTS
import SEO from '../components/seo/SEO'
import Layout from '../layout/layoutBW'
import CardSimple from '../components/portfolioCard/CardSimple'
import InfoCard from "../components/technologie/InfoCard"
import AccordionCardSimple from "../components/accordionCard/AccordionCardSimple";
import Button from "../components/button/Button";

const Baner = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 2rem;

  ${down('sm')} {
    grid-template-columns: 1fr;
    }

  > div {
    
  }
  >div {
    :first-child {
      display: flex;
      flex-direction: column;
  }
}
  
`;

const Card = styled(Link)`
  > div {
    margin-left: 2rem;
    margin-right: 2rem;
    transition: all .5s;
  }

  p {
    transition: all .5s;
  }

  :hover {
    p {
      color: ${props => props.theme.color.grey8};
    }
    > div {
        opacity: .5;
      }
  }

  ${down('sm')} {
    margin-top: 2rem;
    }
`;

const MiniSection = styled.div`
  background-color: ${props => props.theme.color.grey9};
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

const IndexPage = ({
  data: {
    allMdx: {
      nodes
    },
  },
}) => {

  return <div>
    <SEO
      title="Home"
      slug='' />
    <Layout>
      <Section>
        <ContainerMob>
          <LabelBW mb='2rem'>Strony internetowe — Projektowanie graficzne</LabelBW>
          <Baner>
            <div>
              <H1
                xl='65px' l='60px' m='55px' s='50px' xs='45px'
                lineHeight='1.1'
              >
                Responsywne<br />strony internetowe —<br /> indywidualny projekt i realizacja.
          </H1>
              <H4
                as='h2'
                weight='300'
                mt='2rem'>
                Realizujemy strony wizytówki dla małych firm, jak również rozbudowane strony firmowe wzbogacone o system CMS.
              </H4>
              <Button link='/realizacje'>
                Nasze realizacje —{'>'}
              </Button>
            </div>
            <StaticImage
              placeholder='blurred'
              alt='Projektowanie graficzne - ikona'
              src='../assets/images/ic.png'
              layout='fullWidth'
              objectFit='contain'
            />
          </Baner>

        </ContainerMob>
      </Section>

      <Section bgColor={props => props.theme.color.grey9}>
        <ContainerMob>
          <LabelBW mb='2rem'>Oferta</LabelBW>
          <H2>Oferta</H2>
          <Text>
            Realizujemy zarówno strony wizytówki, strony typu one-page, jak również rozbudowane witryny internetowe zintegrowane z CMS (systemem zarządzania treścią).
            </Text>
          <CardWrapper xl='4' l='4' m='4' s='2' xs='1' mt='6rem' mb='0'>
            <Card to='/oferta'>
              <StaticImage
                placeholder='blurred'
                alt='Projektowanie graficzne - ikona'
                src='../assets/images/oferta_projektowanie.png' />
              <LabelBW
                mt='2rem'
                lineHeight='1.3'
                textAlign='center'>
                {proj.title}
              </LabelBW>
            </Card>

            <Card to='/oferta#www'>
              <StaticImage
                placeholder='blurred'
                alt='Strony internetowe - ikona'
                src='../assets/images/oferta_www.png' />
              <LabelBW
                mt='2rem'
                lineHeight='1.3'
                textAlign='center'>
                {www.title}
              </LabelBW>
            </Card>

            <Card to='/oferta#cms'>
              <StaticImage
                placeholder='blurred'
                alt='CMS - ikona'
                src='../assets/images/oferta_cmss.png' />
              <LabelBW
                mt='2rem'
                lineHeight='1.3'
                textAlign='center'>
                {cms.title}
              </LabelBW>
            </Card>

            <Card to='/oferta#app'>
              <StaticImage
                placeholder='blurred'
                alt='Aplikacje - ikona'
                src='../assets/images/oferta_app.png' />
              <LabelBW
                mt='2rem'
                lineHeight='1.3'
                textAlign='center'>
                {app.title}
              </LabelBW>
            </Card>
          </CardWrapper>
        </ContainerMob>
      </Section>

      <ContainerMob>
        <CardWrapper xl='2' l='2' m='2' s='1' xs='1' mt='6rem' mb='6rem' colGap='6rem' rowGap='6rem'>

          {technologie.map((info) => (
            <AccordionCardSimple
              key={info.key}
              subHeading={info.subHeading}
              heading={info.heading}
              intro={info.intro}
              text={info.text}
              list={info.list} />
          ))}

        </CardWrapper>
      </ContainerMob>



      <MiniSection>
        <ContainerMob>
          <LabelBW>Portfolio</LabelBW>
          <H2>Nasze realizacje</H2>
          <Text>
            Zapraszamy do zapoznania się z naszym portfolio, w którym znajdują sie zarówno realizacje stron i aplikacji jak również graficzne projekty, broszur, ulotek wizytówek i logo.
          </Text>
        </ContainerMob>
      </MiniSection>

      <CardWrapper
        colGap='2rem'
        rowGap='2rem' mb='0'
        xl='3' l='3' m='3'
      >
        {nodes.map((node) => {
          return (
            <CardSimple
              key={nodes.indexOf(node)}
              name={node.frontmatter.title}
              shortList={node.frontmatter.shortList}
              link={node.frontmatter.slug}
              image={node.frontmatter.mainImage.file.childImageSharp.gatsbyImageData}
              alt={node.frontmatter.mainImage.alt}
            />
          )
        })}
      </CardWrapper>

    </Layout>
  </div >
}
export default IndexPage

export const query = graphql`
query {
    allMdx (
        filter: {frontmatter: {idPage: {eq: "portfolio"}}}
        sort: {order: DESC, fields: frontmatter___id}
        limit: 3
        ){
        nodes {
            body
            frontmatter {
                title
                intro
                slug
                shortList
                mainImage{
                    alt
                    file {
                        childImageSharp {
                            gatsbyImageData(
                              layout: FULL_WIDTH
                              aspectRatio: 1
                              placeholder: BLURRED
                              )
                          }
                    }
                }
                
            }
        }
    }
}
`