import React from 'react'
import styled from 'styled-components'
//STYLED
import { H6, H3, Text, Label } from '../../utils/typo'
import { theme } from "../../utils/theme";
//COMPONENTS

const InfoWrapper = styled.div`
    display: flex;
  flex-direction: column;
  hr {
    width: 80px;
    margin-left: 0;
  }
`;

class InfoCard extends React.Component {

    render() {
        return (
            <InfoWrapper
                data-sal='fade'
                data-sal-duration='600' >
                <Label
                    as='h5'
                    weight='300'
                    colorFont={theme.color.grey5}>
                    {this.props.subHeading}
                </Label>
                <H3>
                    {this.props.heading}
                </H3>
                <hr />
                <H6
                    weight='500'
                    colorFont={theme.color.font}>
                    {this.props.intro}
                </H6>
                <Text>
                    {this.props.text}
                </Text>

                <ul>
                    {this.props.list.map((li) => (
                        <li key={li}>
                            {li}
                        </li>))}
                </ul>
            </InfoWrapper >
        )
    }
}
export default InfoCard