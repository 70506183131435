import React from "react"
import styled from 'styled-components';
import { motion, AnimatePresence } from "framer-motion";

import { Container } from '../../utils/utils';
import { H3, Text, LabelBW } from '../../utils/typo';
import Button from '../button/Button'

const Header = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-content: center;
  align-items: center;
  justify-items: left;
  background-color: ${({ theme }) => theme.color.baseExtraLight};
  transition: all .5s;

  button {
    justify-self: right;
    align-self: center;
  }
`;

const List = styled.li`
  list-style-type: none;
        margin-left: 2.5rem;
        margin-bottom: 1rem;
        font-weight: 500;
        position: relative;

        ::before {
            content: '—> ';
            display: block;
            position: absolute;
            left: -2.5rem;
        }
`;

class AccordionCardSimple extends React.Component {

  state = { clicked: false }

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked })
  }

  render() {

    return (<Container>

      {/*MENU ROZWIJANE*/}
      <Header>
        <div>
          <LabelBW>{this.props.subHeading}</LabelBW>
          <H3 mb='2rem'>{this.props.heading}</H3>

        </div>

        <Button
          onClick={this.handleClick}
          state={this.state.clicked}
        >
          {this.state.clicked ? 'Zwiń' : 'Więcej'}
        </Button>
      </Header>

      <Text weight='500'>— {this.props.intro}</Text>

      {/*ROZWINIĘCIE*/}
      <AnimatePresence initial={false}>
        {this.state.clicked && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 }
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <Text mt='2rem'
              mb='1rem'>{this.props.text}</Text>
            <ul>
              {this.props.list.map((item) => (
                <List key={this.props.list.indexOf(item)}>{item}</List>
              ))}
            </ul>
          </motion.section>
        )}
      </AnimatePresence>

    </Container>
    )
  }
}
export default AccordionCardSimple